// import {mot} from "react";

const ProjectTitle = ({m,title}) => {
  return (
    <m.div
    initial={{scale:0}}
    whileInView={{scale:1}}
    exit={{scale:0}}
    transition={{duration:1,}}
    className="flex items-center relative my-4 justify-center">
      <img src="/images/star-2.png" alt="" />
      <h1
        style={{ letterSpacing: "8px" }}
        className="text-xl sm:text-2xl md:text-6xl uppercase"
      >
       {title}
      </h1>
      <img src="/images/star-2.png" alt="" />
    </m.div>
  );
};

export default ProjectTitle;
