import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, EffectCoverflow } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";
import ProjectTitle from "../Components/Projects/ProjectTitle";
import { motion as m } from "framer-motion";
import { FaTimes } from "react-icons/fa";
import { useState } from "react";
const cer_list = [
  { id: 1, title: " LEVEL 1 DIPLOMA IN PROFESSIONAL COACHING" },
  { id: 2, title: "  CS50 congratulates" },
  { id: 3, title: " Azure Devops" },
  { id: 1, title: " LEVEL 1 DIPLOMA IN PROFESSIONAL COACHING" },
  { id: 2, title: "  CS50 congratulates" },
  { id: 3, title: " Azure Devops" },
];
const Certificate = () => {
  const [showSwiper, setShowSwiper] = useState(false);
  const [certificate, setCertificate] = useState({});
  return (
    <div className="w-full xl:w-11/12 mx-auto ">
      <ProjectTitle m={m} title="my certificate" />

      <div className="mt-8 py-4">
        <Swiper
          effect={"coverflow"}
          className="mySwiper"
          navigation={true}
          modules={[Navigation, EffectCoverflow]}
          spaceBetween={70}
          slidesPerView={3}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 70,
            modifier: 1,
            slideShadows: false,
          }}
          breakpoints={{
            300: {
              spaceBetween: 50,
              slidesPerView: 1,
              centeredSlides: true,
            },
            776: {
              spaceBetween: 50,
              slidesPerView: 2,
            },
            1300: {
              spaceBetween: 70,
              slidesPerView: 3,
            },
          }}
        >
          {cer_list.map((c, i) => (
            <SwiperSlide key={i}>
              <m.div
                initial={{ scale: 0 }}
                whileInView={{ scale: 1 }}
                transition={{ duration: 0.7, type: "tween" }}
                exit={{ scale: 0 }}
                onClick={() => {setCertificate(c);setShowSwiper(true)}}
                className="bg-box  cursor-pointer p-4 w-96 mx-auto"
              >
                <img
                  src={`/images/cer/cer${c.id}.png`}
                  className="h-64 w-full rounded-lg"
                  alt=""
                />
                <p className="py-2 h-16">{c.title}</p>
              </m.div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className={`swiper-model ${showSwiper && "show"}`}>
        <div className="bg-[#212428] relative w-11/12 h-[90vh] rounded-xl">
          <button
            onClick={() => setShowSwiper(false)}
            className="absolute z-50 left-4 top-4"
          >
            <FaTimes size={24} />
          </button>

          <div className="p-4 md:p-16 w-full mx-auto">
            <img
              src={`/images/cer/cer${certificate.id}.png`}
              className=" object-cover mx-auto rounded-xl"
              alt=""
            />
            <p className="pt-8 text-blue-100 text-center text-base md:text-2xl">
              {certificate.title}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Certificate;
