import { Link } from 'react-router-dom'

const Logo = () => {
  return (
    <Link to='/'
    style={{letterSpacing:"6px"}}
     className='text-xl uppercase cursor-pointer font-bold tracking-widest'>
        <span className='text-blue-600 font-semibold text-4xl'>S</span>
        <span>hahrzad</span>
         </Link>
  )
}

export default Logo