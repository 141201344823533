import AvatarPng from "./../assets/images/avatar.png";
import { FaLinkedin, FaInstagram } from "react-icons/fa6";
import { BsWhatsapp } from "react-icons/bs";
import { BiLogoGmail } from "react-icons/bi";
import { Link } from "react-router-dom";
import ExperinceItem from "../Components/Experience/ExperinceItem";
import { motion as m } from "framer-motion";
const icons = [FaInstagram, FaLinkedin, BsWhatsapp, BiLogoGmail];
const exs = [
  {
    title: "Back End Developer",
    year: "2021-2yrs 2 mos",
    company: "Negar Amitis",
    desc: "Mitoconnect Technologies is a top knowledge-based company specializing in software design and development for IT, IoT, and insurance industries. As the technical and executive arm of central insurance, it offers comprehensive services, including design, development, implementation, maintenance, and technical support for various ICT projects in the insurance sector.",
  },
  {
    title: "Back End Developer",
    year: "2019 - 2021",
    company: "Dadeh Kavi Raeika System Parseh",
    desc: "In the rural cooperative organization, we were working on the Sitra project in a team of 4 people. My task in this project was designing the website and Windows software, coding the portal of the rural cooperative organization with C# language and DDD and Asp.net MVC architecture.In the Agricultural Jihad Organization, I worked in a team of 10 people in the Semak and Sanka Plus.My task in the Semak project was to fix bugs and fix bugs and add new features to the system.And in the Sanka Plus project, we rewrote the system from PHP to ASP.NET Core 3'",
  },
  {
    title:"Frontend Developer",
    year:"2018 - 2019",
    company:"Pishtazan Andisheh Pouya ",
    desc:"Pishtazan Andisheh Pouya is engaged in software development, specifically focusing on designing and developing practical software applications using the latest technologies. Additionally, the company specializes in designing and building national and corporate portals. The company also excels in the areas of analysis, graphic design, and the development and implementation of software systems. The company is devoted to providing our clients with network support and security services."
  }
];
const About = () => {
  return (
    <m.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 3 }}
    className="grid md:grid-cols-5 lg:grid-cols-4   xl:w-10/12 mx-auto">
      <m.div
       initial={{ x:-250  }}
       exit={{ x:250  }}
       whileInView={{ x: 0 }}
       transition={{ duration: 1.2 }}
      className="lg:sticky  top-0 h-screen md:col-span-2 lg:col-span-1">
        <div className="box-grad  flex flex-col sticky-top left-0 items-center gap-6 p-6">
          <img src={AvatarPng} className="rounded-2xl shadow-md" />
          <p className="text-2xl font-bold">Shahrzad Mamourian</p>
          <p className="text-gray-500 text-sm">@shahrzad-mamourian</p>

          <div className="grid grid-cols-4 gap-2">
            {icons.map((Icon, index) => (
              <div key={index} className="social-link">
                <Icon size={24} />
              </div>
            ))}
          </div>
          <Link to="#" className="theme-btn w-full">
            Contact Me
          </Link>
        </div>
      </m.div>
      <div className=" md:col-span-3 p-8 text-justify text-gray-300 leading-6">
        <m.h1  initial={{ y: -250 }}
        animate={{ y: 0 }}
        transition={{ duration:1 }} exit={{y:0}} className="text-xl mb-8 font-bold">ABOUT ME</m.h1>
        <m.p   initial={{ scale: 0.5 }}
        animate={{ scale: 1 }}
        transition={{ duration:1 }} exit={{scale:0}}className="mb-10">
         As a programmer with five years of experience, I am passionate about teamwork, creativity, perseverance, and motivation. Leveraging my work experience as a programmer, I strive to improve work processes and achieve better results in my projects.
        </m.p>
        <m.p   initial={{ scale: 0.5 }}
        animate={{ scale: 1 }}
        transition={{ duration:1 }} exit={{scale:0}}className="mb-10">
         Collaborating with my team, I always aim to bring energy and excitement to the work environment while maintaining high quality and seriousness in my projects. In all the projects I have participated in, I have presented dynamic, advanced, and safe software utilizing my programming skills.
        </m.p>

        <m.h1  initial={{ y: 150 }}
        animate={{ y: 0 }}
        transition={{ duration:1.1 }} exit={{y:0}} className="text-xl mb-8 font-bold">EXPERIENCE</m.h1>
        {exs.map(x=>(
            <ExperinceItem exp={x} key={x.year}/>
        ))}
      </div>
    </m.div>
  );
};

export default About;
