export const skills=[
    {title:"C#",icon:"devicon-csharp-plain colored"},
    {title:"ASP.NET Core",icon:"devicon-dotnetcore-plain"},
    {title:"ASP.NET MVC",icon:"devicon-dot-net-plain"},
    {title:"ASP.NET Framwork",icon:"devicon-dot-net-plain-wordmark colored"},
    {title:"ASP.NET WebApi",icon:"devicon-dot-net-plain colored"},
    {title:"Azure",icon:"devicon-azure-plain colored"},
   
    {title:"Microsof Sql Server",icon:"devicon-microsoftsqlserver-plain-wordmark"},
    {title:"Test Driven Development",icon:"devicon-gitter-plain"},
    {title:"Domain Driven Design",icon:"devicon-devicon-plain"},
    {title:"Html5",icon:"devicon-html5-plain-wordmark colored"},
    {title:"Css3",icon:"devicon-css3-plain-wordmark colored"},
    {title:"Bootstrap",icon:"devicon-bootstrap-plain-wordmark colored"},
    {title:"Javascript",icon:"devicon-javascript-plain colored"},
    {title:"Git",icon:"devicon-git-plain-wordmark colored"},
    {title:"Github",icon:"devicon-github-original-wordmark"},
    {title:"Pyhton",icon:"devicon-python-plain-wordmark colored"},
    {title:"Rest Api",icon:"devicon-appwrite-plain colored"},
    {title:"SaopUi",icon:"devicon-socketio-original"},

]