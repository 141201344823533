import { useCallback } from "react";
import Navbar from "../Components/Navbar";
import { Link, Outlet } from "react-router-dom";
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import { mouseAttraction } from "./../constant/partials";
import Logo from "../Components/Logo";


export const MainLayout = () => {
  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);
  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);

// const {pathname}=useRouter()

  return (
    <main className="w-full md:container">
      <div className="w-full h-full top-0 left-0 opacity-40  absolute">
        <Particles
          options={mouseAttraction}
          init={particlesInit}
          id="tsparticles"
          loaded={particlesLoaded}
        />
      </div>
      <div className="w-full lg:container ">
        <Navbar />
        <div className="min-h-[500px] px-2">
          <Outlet />
        </div>
        <footer className="flex relative py-8 flex-col items-center">
          <Logo/>
          <div className="flex gap-4 text-gray-500 my-3">
            <Link to='/'>Home</Link>
            <Link to='/projects'>Projects</Link>
            <Link to='/skills'>Skills</Link>
            <Link to='/certificate'>certificate</Link>
          </div>
          <p>
            <span>© All rights reserved by </span><span className="font-bold text-blue-500">Shahrzad</span>
          </p>
        </footer>
     
      </div>
    </main>
  );
};
