import ProjectItem from "../Components/ProjectItem";
import ProjectTitle from "../Components/Projects/ProjectTitle";
import {motion as m} from 'framer-motion'
const projects = [
  { id: 1, title: "Taban", year: "2021", skill: "Backend Developer" ,link:"https://tabanapisis.org/"},
  { id: 2, title: "Sanka plus", year: "2021", skill: "Backend Developer" ,link:"#"},
  { id: 3, title: "Semak", year: "2020", skill: "Backend Developer" ,link:"https://semak.maj.ir/"},
  { id: 4, title: "Sitra Plus", year: "2019", skill: "Backend Developer" ,link:""},
  { id: 5, title: "Charisma Broker", year: "2023", skill: "Project manager" ,link:"https://charismabroker.com/"},
  { id: 6, title: "Pepehood", year: "2023", skill: "Frontend Developer" ,link:"https://pepehood.net/"},
];
const Projects = () => {
  return (
    <m.div
    initial={{opacity:0}}
    whileInView={{opacity:1}}
    exit={{opacity:0}}
   
    transition={{duration:1.5,type:"spring"}}
    className="lg:grid lg:grid-cols-3   w-10/12 sm:w-full gap-0 
    md:gap-4 xl:gap-8 
     xl:w-10/12 2xl:w-8/12 mx-auto">
      <div className="col-span-1 grid sm:flex gap-8  lg:block">
        {projects.slice(0, 2).map((project) => (
        <ProjectItem m={m} project={project}/>
        ))}
      </div>
      <div className="col-span-2">
          <ProjectTitle m={m}/>
        <div className="grid sm:grid-cols-2  gap-x-8">
          {projects.slice(2).map((project) => (
            <ProjectItem m={m} project={project}/>
          ))}
        </div>
      </div>
    </m.div>
  );
};

export default Projects;
