import "./assets/css/styles.css";
import AvatarPng from "./assets/images/avatar.png";
import MyWorks from "./assets/images/my-works.png";
import Box from "./Components/Box";
import { Link } from "react-router-dom";
import { FaLinkedin, FaInstagram } from "react-icons/fa6";
import { BsWhatsapp } from "react-icons/bs";
import { BiLogoGmail } from "react-icons/bi";

import {  useEffect, useRef } from "react";
import Typed from "typed.js";
import { motion as m } from "framer-motion";
function App() {
  const infoEl = useRef(null);
  useEffect(() => {
    // const nameTypes = new Typed(nameEl.current, {
    //   strings: ["Shahrzad Mamourian"],
    //   typeSpeed: 80,
    //   backSpeed: 50,
    //   backDelay: 10,
    //   showCursor: false,
    // });
    const infoTyped = new Typed(infoEl.current, {
      strings: [
        " Asp.Net developer at Mitoconnect",
        "C# Developer",
        "SQL Server Managment",
      ],
      typeSpeed: 100,
      startDelay: 2000,
      loop: true,
      backDelay: 50,
      backSpeed: 50,
      showCursor: false,
    });
    return () => {
      infoTyped.destroy();
    };
  }, []);


  return (
    <>
      <m.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 3 }}
        className="tracking-widest"
      >
        <div className="grid lg:grid-cols-2 gap-4  md:gap-8   xl:h-80">

          <Link to="/about" className="">
            <m.div
              initial={{ scale: 0 }}
              whileInView={{ scale:1}}
              animate={{ scale:1}}
              transition={{ duration: 1.5 }}
              exit={{scale: 0.5 }}
              className="h-full w-full"
            >
              <Box>
                <div className="flex flex-col gap-4 lg:flex-row p-2">
               
                 <m.img
                    initial={{ scale: 0.6 }}
                    animate={{ scale:1}}
                    whileInView={{ scale: 1 }}
                    transition={{ duration: 1 }}
                    exit={{ scale: 0 }}
                    className=" shadow-xl avatar w-48 lg:w-60 xl:w-64"
                    src={AvatarPng}
                    alt=""
                  />
                
                  <div className=" flex flex-col items-start  gap-1 md:gap-4">
                    <p className="text-subtitle">
                      Asp.Net developer
                    </p>
                    <h1 className="text-xl md:text-2xl xl:text-3xl font-extrabold">
                      Shahrzad <br /> Mamourian
                    </h1>
                    <p className="text-subtitle pr-6">
                      Asp.Net developer at Mitoconnect(Central Insurance of IR)
                      | 📒 Love to learning
                    </p>
                  </div>
                </div>
              </Box>
            </m.div>
          </Link>


          <div className="uppercase">
            <m.div
              initial={{ scale: 0 }}
              animate={{ scale:1}}
              whileInView={{ scale: 1 }}
              transition={{ duration: 1.5 }}
              exit={{ scale: 0 }}
              className="rounded-full shadow-2xl text-sm sm:text-base
               mb-4 gap-4  bg-grad  flex justify-center px-4 items-center h-16"
            >
              <p ref={infoEl}></p>
            </m.div>
            <div className="grid sm:grid-cols-2 gap-4 lg:gap-8 xl:h-72">
              <m.div
                 initial={{ scale: 0 }}
                 animate={{ scale:1}}
                 whileInView={{ scale:1}}
                 transition={{ duration: 1.5 }}
                 exit={{scale: 0 }}
                className="h-full"
              >
                <Box>
                  <Link to="/projects" 
                  className="flex  flex-col justify-center lg:justify-around 
                  h-full w-full  xl:items-center">
                    <m.img
                     initial={{ scale:0 }}
                     animate={{ scale:1}}
                     whileInView={{ scale:1 }}
                     transition={{ duration: 1.4 }}
                     exit={{ scale:0 }}
                    src={MyWorks} className="aspect-video" alt="" />
                    <div className="">
                      <p className="text-subtitle mb-1 text-xs">SHOWCASE</p>
                      <p className="text-xl xl:text-2xl">Projects</p>
                    </div>
                  </Link>
                </Box>
              </m.div>
              <m.div
                  initial={{ scale: 0 }}
                  whileInView={{ scale:1}}
                  transition={{ duration: 1.5 }}
                  exit={{scale: 0 }}
                className="h-full"
              >
                <Box>
                  <Link to="/certificate" className="flex  flex-col justify-center 
                  lg:justify-around h-full w-full  xl:items-center">
                  <m.img
                   animate={{ scale:1}}
                    initial={{ scale:0 }}
                     whileInView={{ scale:1 }}
                     transition={{ duration: 1.4 }}
                     exit={{ scale:0 }}
                      src="/images/cer.png"
                      className="h-40 w-60 aspect-video object-cover mt-8 lg:mt-0 mx-auto lg:mx-0 rounded-xl mb-4"
                      alt=""
                    />
                    <div className="">
                      <p className="text-subtitle mb-1 text-xs">
                        MORE ABOUT ME
                      </p>
                      <p className="text-xl xl:text-2xl">Certificate</p>
                    </div>
                  </Link>
                </Box>
              </m.div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2 xl:grid-cols-4  mt-8 sm:mt-8 md:mt-12 xl:mt-20 uppercase gap-4 xl:gap-8 ">
          <m.div
              initial={{ scale: 0 }}
              animate={{ scale:1}}
              whileInView={{ scale:1}}
              transition={{ duration: 1.4 }}
              exit={{scale: 0 }}
            className="md:col-span-1 col-span-2  xl:order-first"
          >
            <Box>
              <Link to='/articles' className="flex flex-col justify-center xl:justify-around w-full h-full items-center">
                <m.img
                 initial={{ scale:0 }}
                 animate={{ scale:1}}
                 whileInView={{ scale:1 }}
                 transition={{ duration: 1.4 }}
                 exit={{ scale:0 }}
                  src="/images/ar.png"
                  alt=""
                  className="w-32  lg:w-[160px]"
                />
                <p className="text-left w-full text-base">
                  Articles
                </p>
              </Link>
            </Box>
          </m.div>
          <m.div
              initial={{ scale: 0 }}
              animate={{ scale:1}}
              whileInView={{ scale:1}}
              transition={{ duration: 1.5 }}
              exit={{scale: 0 }}
            className="col-span-2  order-first "
          >
            <Box>
              <Link to='/skills' className="flex justify-between gap-8  flex-col w-full">
                <div className="grid grid-cols-5 animate-pulse justify-center items-center w-full gap-1 md:gap-8">
                  <m.img
                    initial={{ y: 150 }}
                    exit={{ y: 0 }}
                    whileInView={{ y: 1 }}
                    transition={{ duration: 1.5 }}
                    src="/images/c.png"
                    className="w-24"
                    alt=""
                  />
                  <m.img
                    initial={{ y: 150 }}
                    exit={{ y: 0 }}
                    whileInView={{ y: 1 }}
                    transition={{ duration: 1.5 }}
                    src="/images/asp.png"
                    className="w-24 hue-rotate-30"
                    alt=""
                  />
                  <m.img
                    initial={{ y: 150 }}
                    exit={{ y: 0 }}
                    whileInView={{ y: 1 }}
                    transition={{ duration: 1.5 }}
                    src="/images/sql.png"
                    className="w-24 "
                    alt=""
                  />
                  <m.img
                    initial={{ y: 150 }}
                    exit={{ y: 0 }}
                    whileInView={{ y: 1 }}
                    transition={{ duration: 1.5 }}
                    src="/images/js.png"
                    className="w-24 invert"
                    alt=""
                  />

                  <m.img
                    initial={{ y: 150 }}
                    exit={{ y: 0 }}
                    whileInView={{ y: 1 }}
                    transition={{ duration: 1.5 }}
                    src="/images/html.png"
                    className="w-24 hue-rotate-90"
                    alt=""
                  />
                </div>
                <div className="ml-12 mt-8">
                  <p className="text-subtitle mb-1 text-xs">SPECIALIZATION</p>
                  <p className=" text-2xl"> Skills</p>
                </div>
              </Link>
            </Box>
          </m.div>
          <m.div
             initial={{ scale: 0 }}
             animate={{ scale:1}}
             whileInView={{ scale:1}}
             transition={{ duration: 1.5 }}
             exit={{scale: 0 }}
            className="md:col-span-1 col-span-2"
          >
            <Box>
              <div className="flex flex-col justify-around w-full h-full items-center">
                <div className="flex bg-grad justify-around mb-2 py-4 rounded-xl w-full">
                  <m.a
                  target="_blank"
                  href="https://www.linkedin.com/in/shahrzad-mamourian/"
                  initial={{ scale:0 }}
                  animate={{ scale:1}}
                   whileInView={{ scale:1 }}
                   transition={{ duration: 2 }}
                   exit={{ scale:0 }}
                  className="bg-grad p-4 rounded-full">
                    <FaLinkedin color="#0A66C2" size={32} />
                  </m.a>
                  <m.a
                  target="_blank"
                  href="https://www.instagram.com/shahrzad.mamourian/"
                   initial={{ scale:0 }}
                   whileInView={{ scale:1 }}
                   transition={{ duration: 2 }}
                   exit={{ scale:0 }}
                  className="bg-grad p-4 rounded-full">
                    <FaInstagram color="#EA6155" size={32} />
                  </m.a>
                  <m.a
                  href="https://wa.me/09228106545"
                  target="_blank"
                   initial={{ scale:0 }}
                   whileInView={{ scale:1 }}
                   transition={{ duration: 2 }}
                   exit={{ scale:0 }}
                  className="bg-grad p-4 rounded-full">
                    <BsWhatsapp color="#40E15D" size={32} />
                  </m.a>
                  <m.a
                    href="mailto:shahrzadmamourian2@gmail.com"
                    target="_blank"
                   initial={{ scale:0 }}
                   whileInView={{ scale:1 }}
                   transition={{ duration: 2 }}
                   exit={{ scale:0 }}
                  className="bg-grad p-4 rounded-full">
                    <BiLogoGmail color="crimson" size={32} />
                  </m.a>
                </div>
                <div className="">
                  <p className="text-subtitle mb-1 text-xs">STAY WITH ME</p>
                  <p className=" text-base">Contact</p>
                </div>
              </div>
            </Box>
          </m.div>
        </div>
      </m.div>
    </>
  );
}

export default App;
