import React from 'react'
import { useParams } from 'react-router-dom'
import ArticleA from '../Components/articles/ArticleA'
import ArticleB from '../Components/articles/ArticleB'

const Article = () => {
    const {id}=useParams()
  return (
    <div>{id==="1"?<ArticleA/>:<ArticleB/>}</div>
  )
}

export default Article