import React from "react";
import { Link } from "react-router-dom";
import ProjectTitle from "../Components/Projects/ProjectTitle";
import { motion as m } from "framer-motion";
const Articles = () => {
  return (
    <>
      <ProjectTitle title="Articles" m={m} />
      <div className="grid grid-cols-1 px-2 md:grid-cols-2 xl:grid-cols-3 relative gap-8 mt-16">
        <m.div
          initial={{ scale: 0 }}
          whileInView={{ scale: 1 }}
          transition={{ duration: 0.7,delay:0.3, type: "tween" }}
          exit={{ scale: 0 }}
        >
          <Link to="/article/1" className="bg-box block h-64 p-8">
            <p className="text-2xl h-24 mb-8 leading-10">
              Refactoring Legacy Projects Using ASP.NET: Challenges and
              Solutions
            </p>
            <p className="text-gray-500 mb-1">March 14, 2023</p>
            <p className="text-blue-500">Shahrzad Mamourian</p>
          </Link>
        </m.div>
        <m.div
          initial={{ scale: 0 }}
          whileInView={{ scale: 1 }}
          transition={{ duration: 0.7,delay:0.5, type: "tween" }}
          exit={{ scale: 0 }}
        >
         <Link to="/article/2" className="bg-box block h-64 p-8">
          <p className="text-2xl mb-8 h-24 leading-10">
            Aspect Oriented Programming
          </p>
          <p className="text-gray-500 mb-1">April 11, 2023</p>
          <p className="text-blue-500">Shahrzad Mamourian</p>
        </Link>   
        </m.div>
        
      </div>
    </>
  );
};

export default Articles;
